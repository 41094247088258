import { alpha } from '@material-ui/core/styles';
import styled from 'styled-components';

import { containerLargeWidth, palette, Theme } from '../../theme';
import { ButtonBase } from '../Button';
import { ButtonProperties } from '../Button/base/Button.types';
import { Heading } from '../Heading';
import { ImageStandalone } from '../ImageStandalone';
import { RoundelPosition } from './HeroWithOverlay.types';

export const StyledWrapper = styled.div`
    position: relative;
    line-height: 0;
`;

export const StyledLabel = styled.div`
    color: ${palette.common.blue};
    font-size: 1.4rem;
    font-family: thegymgroupsans-headline;
    text-transform: lowercase;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.8rem;
    }
`;

export const StyledHeading = styled(Heading)`
    margin: 0;
    color: ${palette.common.white};
`;

export const StyledButton = styled(ButtonBase)<ButtonProperties>`
    width: 100%;
    margin: 0.5rem 0;
    font-size: 1.8rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        width: auto;
        margin: 1.5rem 1rem 1.5rem 0;
        font-size: 1.9rem;
    }
`;

export const StyledHeadingUnderlay = styled.div`
    width: 100%;
    margin-top: auto;
    line-height: 1.8rem;
    background: ${({ theme }) => alpha(palette.primary.main, 0.9)};

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        line-height: 2.2rem;
    }
`;

export const StyledHeadingContent = styled.div`
    max-width: ${containerLargeWidth};
    margin: 0 auto;
    padding: 1.5rem 3rem;
`;

export const StyledImageStandalone = styled(ImageStandalone)`
    ${({ theme }: { theme: Theme }) =>
        theme.breakpoints.up('extraLargeDesktop')} {
        min-height: 65vh;
    }
`;

export const StyledContainer = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
`;

export const StyledRoundelContainer = styled.div`
    position: relative;
    flex-grow: 1;
`;

export const StyledRoundel = styled(ImageStandalone)<{
    position: RoundelPosition;
}>`
    position: absolute;
    width: auto;
    max-width: 50%;
    max-height: 50%;

    ${({ position }) => {
        if (position === 'center right') {
            return `
                top: 50%;
                right: 10%;
                transform: translateY(-50%);
            `;
        }

        return `
            top: 10%;
            right: 10%;
        `;
    }};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('largeDesktop')} {
        max-width: 40rem;
        max-height: 60%;
    }
`;
