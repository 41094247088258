import { useCallback, useState, ChangeEvent, FormEvent } from 'react';

import { palette } from '../../theme';
import {
    StyledPromoCodeTile,
    StyledTextInputContainer,
    StyledHeading,
    StyledTextInput,
} from './PromoCodeTile.styled';
import { PromoCodeTileProperties } from './PromoCodeTile.types';
import { isApiErrorResponse } from '@tgg/util';

export function PromoCodeTile({
    onSubmit,
    initialValue = '',
    gutterBottom,
}: PromoCodeTileProperties) {
    const [value, setValue] = useState(() => initialValue);
    const [isValid, setIsValid] = useState(() => !!initialValue || false);
    const [errorMessage, setErrorMessage] = useState(() => '');

    const submitHandler = async (event?: FormEvent<HTMLFormElement>) => {
        if (event) {
            event.preventDefault();
        }
        setErrorMessage('');
        try {
            await onSubmit(value);
            setIsValid(true);
        } catch (error) {
            const errorDetailText =
                isApiErrorResponse(error) && error?.response?.data.error.detail;
            setErrorMessage(errorDetailText || (error as Error).message);
            setIsValid(false);
        }
    };

    const iconElementRight = isValid
        ? ({
              name: 'close',
              color: palette.primary.main,
              onClick: async () => {
                  setValue('');
                  setIsValid(false);
                  await onSubmit();
              },
              size: 18,
          } as const)
        : ({
              name: 'plus',
              color: palette.primary.main,
              type: 'submit',
              onClick: async () => {
                  await submitHandler();
              },
          } as const);

    const handleOnChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            setValue(event.target.value);
            setErrorMessage('');
        },
        [],
    );

    return (
        <StyledPromoCodeTile gutterBottom={gutterBottom}>
            <StyledHeading variant="h3" color={palette.common.blue}>
                Promo Code
            </StyledHeading>
            <StyledTextInputContainer>
                <form onSubmit={submitHandler}>
                    <StyledTextInput
                        value={value}
                        onChange={handleOnChange}
                        isInvalid={!!errorMessage}
                        iconElementRight={iconElementRight}
                        placeholder="add promo code"
                        disabled={isValid}
                        errorMessage={errorMessage}
                        data-testid="promo-code-input"
                    />
                </form>
            </StyledTextInputContainer>
        </StyledPromoCodeTile>
    );
}
