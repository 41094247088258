import styled from 'styled-components';

import { Theme } from '../../theme';
import { Heading } from '../Heading';

export const StyledWrapper = styled.div`
    margin: 0 3rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 84.4rem;
        margin: 0 auto;
    }
`;

export const StyledHeading = styled(Heading)<{ $withSpacing: boolean }>`
    margin-bottom: ${({ $withSpacing }) => ($withSpacing ? '3rem' : 'revert')};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin-bottom: ${({ $withSpacing }) =>
            $withSpacing ? '6rem' : 'revert'};
    }
`;
