import styled from 'styled-components';

import { Theme } from '../../../theme';
import { Heading } from '../../Heading';

export const StyledWrapper = styled.div`
    max-width: 84.6rem;
    margin: 0 auto;
    padding: 0 3rem 3rem 3rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding: 0 0 3rem 0;
        text-align: center;
    }
`;

export const StyledHeading = styled(Heading)`
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding-bottom: 0.5rem;
    }
`;

export const StyledPaymentInformationSection = styled.section`
    margin-bottom: 3rem;
`;
