import { heightMobile, overrideSizes } from './HeroWithOverlay.constants';
import {
    StyledWrapper,
    StyledHeadingUnderlay,
    StyledHeadingContent,
    StyledLabel,
    StyledHeading,
    StyledButton,
    StyledImageStandalone,
    StyledRoundel,
    StyledRoundelContainer,
    StyledContainer,
} from './HeroWithOverlay.styled';
import { HeroWithOverlayProperties } from './HeroWithOverlay.types';
import { EventKey, CtaButtonClickPayload } from '@tgg/services';

/**
 * The `HeroWithOverlay` component contains an image, title, label above the title, as well as up to two CTAs (primary and secondary style).
 */
export function HeroWithOverlay({
    id,
    title,
    label,
    image,
    buttons = [],
    roundel,
    objectPosition,
}: HeroWithOverlayProperties) {
    const [firstButtonProperties, secondButtonProperties] = buttons;

    return (
        <StyledWrapper id={id}>
            <StyledImageStandalone
                {...{
                    ...image,
                    height: 640,
                    maximumMobileImageHeight: heightMobile,
                    overrideSizes,
                    objectPosition,
                }}
            />

            <StyledContainer>
                {roundel && roundel?.image && (
                    <StyledRoundelContainer data-testid="roundel">
                        <StyledRoundel
                            {...roundel?.image}
                            position={roundel.position}
                            height="auto"
                            width={roundel?.width || 'auto'}
                        />
                    </StyledRoundelContainer>
                )}

                <StyledHeadingUnderlay>
                    <StyledHeadingContent>
                        {label && <StyledLabel>{label}</StyledLabel>}
                        <StyledHeading variant="h1" gutterBottom={false}>
                            {title}
                        </StyledHeading>
                        {firstButtonProperties && (
                            <StyledButton
                                {...firstButtonProperties}
                                buttonStyle="primary-alternate"
                                data-analytics-event={EventKey.CTA_BUTTON_CLICK}
                                data-analytics-payload={JSON.stringify({
                                    ctaName: firstButtonProperties.text,
                                    ctaPosition: 'HeroWithOverlay',
                                } as CtaButtonClickPayload)}
                            />
                        )}
                        {secondButtonProperties && (
                            <StyledButton
                                {...secondButtonProperties}
                                buttonStyle="secondary-white"
                                data-analytics-event={EventKey.CTA_BUTTON_CLICK}
                                data-analytics-payload={JSON.stringify({
                                    ctaName: secondButtonProperties.text,
                                    ctaPosition: 'HeroWithOverlay',
                                } as CtaButtonClickPayload)}
                            />
                        )}
                    </StyledHeadingContent>
                </StyledHeadingUnderlay>
            </StyledContainer>
        </StyledWrapper>
    );
}

export default HeroWithOverlay;
