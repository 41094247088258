import styled from 'styled-components';

import { TextInput } from '../TextInput';

export const StyledForm = styled.form`
    position: relative;
`;

export const StyledTextInput = styled(TextInput)`
    padding-right: 9rem;
`;

export const StyledContainerForButtons = styled.div`
    position: absolute;
    top: 0;
    right: 1rem;
    display: flex;
    align-items: center;
    height: 100%;
`;

export const StyledClearButton = styled.button`
    color: inherit;
    font-size: 1.4rem;
    text-decoration: underline;
    background-color: transparent;
    border: none;
    cursor: pointer;
`;

export const StyledButton = styled.button`
    padding: ${({ theme }) => theme.spacing(5)};
    background: none;
    border: none;
    cursor: pointer;

    svg {
        cursor: pointer;
    }
`;
