import styled from 'styled-components';

import { Theme } from '../../theme';

export const StyledWrapper = styled.div`
    max-width: 62.8rem;
    margin: 3rem auto 4rem auto;
    padding: 0 3rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding: 0;
    }
`;
