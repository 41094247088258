import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';

import { palette, theme } from '../../theme';
import { Icon } from '../Icon';
import { Tile } from '../Tile';
import {
    StyledAddress,
    StyledBody,
    StyledContent,
    StyledContentHeading,
    StyledDescription,
    StyledHeading,
    StyledIcon,
    StyledItem,
    StyledOpeningHoursItem,
    StyledOpeningTimeAndDayWrapper,
    StyledOpeningTimesDay,
    StyledOpeningTimesTime,
} from './AboutYourGym.styled';
import { AboutYourGymProperties } from './AboutYourGym.types';
import {
    formatAllOpeningHours,
    isOpen24Hours,
    openingHoursAsString,
} from '@tgg/util';

/**
 * AboutYourGym component is is used to show details of the home gym of the user, i.e. on the confirmation page following a change of the home gym.
 */

export function AboutYourGym({
    gymName,
    openingHours,
    address,
}: AboutYourGymProperties) {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const iconSize = isDesktop ? 34 : 22;
    const formattedOpeningHours =
        openingHours && formatAllOpeningHours(openingHours);
    const isFully24Hours = openingHours && isOpen24Hours(openingHours);
    return (
        <>
            <StyledHeading variant="h2" gutterBottom={false}>
                Where to find us
            </StyledHeading>
            <Tile title="About your gym">
                <StyledBody>
                    <StyledItem>
                        <StyledIcon>
                            <Icon
                                name="myGym"
                                color={palette.primary.main}
                                size={iconSize}
                            />
                        </StyledIcon>
                        <StyledContent>
                            <StyledContentHeading
                                variant="h4"
                                gutterBottom={false}
                            >
                                {gymName}
                            </StyledContentHeading>
                            <StyledDescription>Your home gym</StyledDescription>
                        </StyledContent>
                    </StyledItem>
                    <StyledOpeningHoursItem>
                        <StyledIcon>
                            <Icon
                                name="openingHours"
                                color={palette.primary.main}
                                size={iconSize}
                            />
                        </StyledIcon>
                        <StyledContent>
                            <StyledContentHeading
                                variant="h4"
                                gutterBottom={false}
                            >
                                Opening hours
                            </StyledContentHeading>
                            <StyledDescription>
                                {isFully24Hours
                                    ? openingHoursAsString(openingHours)
                                    : formattedOpeningHours?.map(
                                          ({ day, hours }) => (
                                              <StyledOpeningTimeAndDayWrapper
                                                  key={day}
                                              >
                                                  <StyledOpeningTimesDay
                                                      id={day}
                                                      gutterBottom={false}
                                                  >
                                                      {day}
                                                  </StyledOpeningTimesDay>
                                                  <StyledOpeningTimesTime
                                                      aria-labelledby={day}
                                                      gutterBottom={false}
                                                  >
                                                      {hours}
                                                  </StyledOpeningTimesTime>
                                              </StyledOpeningTimeAndDayWrapper>
                                          ),
                                      )}
                            </StyledDescription>
                        </StyledContent>
                    </StyledOpeningHoursItem>
                    <StyledItem>
                        <StyledIcon>
                            <Icon
                                name="gymLocation"
                                color={palette.primary.main}
                                size={iconSize}
                            />
                        </StyledIcon>
                        <StyledContent>
                            <StyledContentHeading
                                variant="h4"
                                gutterBottom={false}
                            >
                                location
                            </StyledContentHeading>
                            <StyledAddress>
                                {address?.address1}
                                <br />
                                {address?.address2}
                                <br />
                                {address?.address3 && (
                                    <>
                                        {address.address3}
                                        <br />
                                    </>
                                )}
                                {address?.city}
                                <br />
                                {address?.postcode}
                            </StyledAddress>
                        </StyledContent>
                    </StyledItem>
                </StyledBody>
            </Tile>
        </>
    );
}
