import styled from 'styled-components';

import { palette } from '../../../theme';

const heightMobile = 110;
const widthMobile = 110;

const heightDesktop = 220;
const widthDesktop = 300;

export const desktopImageSizes = [heightDesktop, widthDesktop];
export const mobileImageSizes = [heightMobile, widthMobile];

export const StyledContainer = styled.div`
    width: fit-content;
    background-color: ${palette.common.white};
`;

export const StyledImageContainer = styled.div`
    width: ${({ theme }) => theme.typography.pxToRem(widthMobile)};
    height: ${({ theme }) => theme.typography.pxToRem(heightMobile)};

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        width: ${({ theme }) => theme.typography.pxToRem(widthDesktop)};
        height: ${({ theme }) => theme.typography.pxToRem(heightDesktop)};
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 0.8rem;
    }
`;

export const StyledHeading = styled.div`
    width: 11rem;
    margin: 1.5rem 0 0.5rem;
    color: ${palette.primary.main};
    font-size: 1.9rem;
    font-family: thegymgroupsans-headline;
    text-transform: lowercase;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        width: 30rem;
        font-size: 2.1rem;
    }
`;

export const StyledStoryContainer = styled.div`
    width: 100vw;
    height: 100vh;
    padding: 2rem;
    background-color: ${palette.grey[100]};
`;
