/* istanbul ignore file */
import styled from 'styled-components';

import { Theme } from '../../theme';
import { Paragraph } from '../Paragraph';
import { StyledHeadingProperties } from './ListWithIcons.types';

export const StyledHeadingWrapper = styled.div`
    padding: 0;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding: 0 ${({ theme }) => theme.spacing(30)};
    }
`;

export const StyledHeading = styled(Paragraph)<StyledHeadingProperties>`
    padding: ${({ theme }: { theme: Theme }) => `${theme.spacing(10)} 0`};
    font-weight: ${({ $titleBold }) => ($titleBold ? 'bold' : 'normal')};
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding: ${({ theme }: { theme: Theme }) =>
            `${theme.spacing(15)} 0 ${theme.spacing(10)} 0`};
        text-align: center;
    }
`;

export const StyledList = styled.ul`
    width: 100%;
    margin: 0;
    padding: 0;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const StyledListContent = styled.div`
    display: flex;
    padding: 0.5rem 0;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        width: 47rem;
        padding: 1rem 1.5rem 1rem 3rem;
    }
`;

export const StyledIconContainer = styled.span`
    width: 2.2rem;
    height: 2.2rem;
`;

export const StyledParagraph = styled(Paragraph)`
    display: inline-block;
    padding-left: ${({ theme }: { theme: Theme }) => theme.spacing(8)};
    b {
        font-family: thegymgroupsans-Headline, sans-serif;
    }
`;
