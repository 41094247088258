import styled from 'styled-components';

import { Theme, palette } from '../../theme';
import { ButtonBase } from '../Button';
import { Heading } from '../Heading';
import { ImageStandalone } from '../ImageStandalone';
import { Paragraph } from '../Paragraph';
import { StoryBoxSize, StoryBoxVariant } from './StoryBox.types';

export const StyledWrapper = styled.article<{
    $variant: StoryBoxVariant;
}>`
    display: flex;
    flex: 1 1 30%;
    flex-direction: column;
    height: 100%;
    background-color: ${({ $variant }) =>
        $variant === 'light blue' ? palette.grey[50] : palette.common.white};
    border: ${({ $variant }) =>
        $variant === 'light blue'
            ? `none`
            : `0.2rem solid ${palette.common.lightGrey}`};
    border-radius: 1rem;

    ${({ $variant }) => {
        if ($variant === 'light blue') {
            return `
                h3 {
                    color: ${palette.common.blue};
                }
            `;
        }

        return ``;
    }};
`;

export const StyledContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 2rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up(1024)} {
        padding: 4rem;
    }
`;

export const StyledTagsList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    padding: 0;
    list-style: none;
`;

export const StyledTagItem = styled.li`
    font-size: 1.4rem;

    &:nth-child(n + 1)::after {
        margin-right: 0.5rem;
        margin-left: 0.5rem;
        color: ${palette.primary.main};
        content: '+';
    }

    &:last-child::after {
        display: none;
    }
`;

export const StyledTagLink = styled.a`
    color: ${palette.common.blue};
    text-transform: lowercase;
    text-decoration: none;
`;

export const StyledImageStandalone = styled(ImageStandalone)<{
    $size: StoryBoxSize;
}>`
    display: flex;
    height: 18.2rem;
    border-radius: 1rem 1rem 0 0;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        height: ${({ $size }) => ($size === 'large' ? '19.9rem' : '12.8rem')};
    }

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up(1024)} {
        height: ${({ $size }) => ($size === 'large' ? '36.4rem' : '23.9rem')};
    }
`;

export const StyledHeading = styled(Heading)`
    font-size: 2.3rem;
    line-height: 2.8rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up(1024)} {
        font-size: 2.6rem;
        line-height: 3rem;
        letter-spacing: -0.15rem;
    }
`;

export const StyledParagraph = styled(Paragraph)`
    font-size: 1.4rem;
    line-height: 2rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up(1024)} {
        font-size: 1.6rem;
        line-height: 2.2rem;
    }
`;

export const StyledButtonContainer = styled.div`
    margin-top: auto;
`;

export const StyledButton = styled(ButtonBase)`
    width: 100%;
    padding: 0.2rem 1rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        width: auto;
    }
`;

export const StyledPill = styled.div`
    position: absolute;
    top: 0;
    left: 3rem;
    padding: 0 1rem;
    color: ${palette.common.blue}6;
    font-size: 1.4rem;
    font-family: thegymgroupsans-headline;
    line-height: 1.8rem;
    letter-spacing: -0.2%;
    text-align: center;
    background-color: ${palette.secondary.main};
    border-radius: 2rem;
    transform: translateY(-50%);

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up(1024)} {
        font-size: 1.8rem;
        line-height: 2.5rem;
    }
`;
