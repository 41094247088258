import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { endOfMonth, addMonths } from 'date-fns';
import { Controller } from 'react-hook-form';

import { Asterisk } from '../../Asterisk';
import { Icon } from '../../Icon';
import { StyledLabel } from '../ControlledInputs.styled';
import {
    StyledDateInput,
    StyledDateInputContainer,
} from './ControlledDateInput.styled';
import { ControlledDateInputProperties } from './ControlledDateInput.types';
import { disableSpecificDates, renderDay } from './utils';

export function ControlledDateInput({
    control,
    placeholder,
    disabledDates = [],
    disabled,
    id,
    label,
    isRequired,
}: ControlledDateInputProperties) {
    const maxDate = endOfMonth(addMonths(new Date(), 1));

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <Controller
                name={id}
                control={control}
                render={({ field }) => (
                    <>
                        {label && (
                            <StyledLabel
                                $hasPaddingTop
                                data-testid={`${id}-label`}
                                htmlFor={id}
                            >
                                {label}
                                {isRequired && <Asterisk spaceBefore />}
                            </StyledLabel>
                        )}
                        <StyledDateInputContainer>
                            <StyledDateInput
                                disableToolbar
                                variant="dialog"
                                id={id}
                                format="dd MMM yyyy"
                                placeholder={placeholder}
                                disablePast
                                maxDate={maxDate}
                                shouldDisableDate={(...parameters) =>
                                    disableSpecificDates(
                                        ...parameters,
                                        disabledDates,
                                    )
                                }
                                disabled={disabled}
                                InputProps={{
                                    disableUnderline: true,
                                    endAdornment: <Icon name="classes" />,
                                }}
                                fullWidth
                                renderDay={(...parameters) =>
                                    renderDay(...parameters, disabledDates)
                                }
                                {...field}
                            />
                        </StyledDateInputContainer>
                    </>
                )}
            />
        </MuiPickersUtilsProvider>
    );
}

export default ControlledDateInput;
