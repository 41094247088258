import styled from 'styled-components';

import { lightGreyBoxShadow, Theme } from '../../theme';
import { ButtonBase } from '../Button';
import { Heading } from '../Heading';
import { ImageStandalone } from '../ImageStandalone';
import { Paragraph } from '../Paragraph';

export const tileWidthMobile = '31.6rem';
export const tileWidthDesktop = '106.4rem';
export const tileImageWidthDesktop = '35.8rem';

interface StyledWrapperProperties {
    $fullWidth: boolean;
}

export const StyledWrapper = styled.div<StyledWrapperProperties>`
    width: ${properties => (properties.$fullWidth ? '100%' : tileWidthMobile)};
    margin: 2.4rem auto;
    overflow: hidden;
    border-radius: 1rem;
    box-shadow: ${lightGreyBoxShadow};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        width: calc(100%);
        max-width: ${properties =>
            properties.$fullWidth ? '100%' : tileWidthDesktop};
        padding-bottom: 2rem;
    }
`;

export const StyledImageStandalone = styled(ImageStandalone)`
    height: 15rem;
    object-fit: cover;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        height: ${tileImageWidthDesktop};
    }
`;

export const StyledHeading = styled(Heading)`
    margin-bottom: 1.5rem;
    font-size: 2.3rem;
    line-height: 2.6rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 2.6rem;
        line-height: 3rem;
    }
`;

export const StyledCTASection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 1.4rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        flex-direction: row;
    }
`;

export const StyledButton = styled(ButtonBase)`
    margin: 0.8rem 0;
    font-size: 1.8rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        width: 27.6rem;
        margin: 1.2rem 0.5rem 0 0.5rem;
    }
`;

interface HeadingContentProperties {
    $alignText: 'left' | 'center' | 'right';
    $extraPadding: boolean;
}

export const StyledHeadingContent = styled.div<HeadingContentProperties>`
    padding: ${properties =>
        properties.$extraPadding ? '2rem 3rem 3rem' : '2rem'};
    text-align: ${properties => properties.$alignText};

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        padding: ${properties =>
            properties.$extraPadding ? '3.5rem 10.9rem 6rem 10.9rem' : '2rem'};
    }
`;

export const StyledParagraph = styled(Paragraph)`
    font-size: 1.4rem;
    line-height: 2rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.6rem;
        line-height: 2.2rem;
    }
`;

export const StyledGymLocationHeading = styled(Paragraph)`
    font-weight: normal;
    font-family: thegymgroupsans-Headline;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.6rem;
        line-height: 2.2rem;
    }
`;
