import styled from 'styled-components';

import { palette } from '../../theme';
import { ButtonBase } from '../Button';

export const StyledSummary = styled.div`
    padding: 0 1.5rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        padding: 0 3rem;
    }
`;

export const StyledSelection = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        display: inline-flex;
    }
    justify-content: space-between;
`;

export const StyledSelectionTitle = styled.div`
    display: inline-flex;
    padding-right: 1rem;
    padding-bottom: 0.5rem;
    font-weight: normal;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        padding-bottom: 0;
    }
`;

export const StyledSelectionValue = styled.div`
    display: inline-flex;
    font-family: thegymgroupsans-Headline;
`;

export const StyledUserSelection = styled.div`
    box-sizing: border-box;
    width: 100%;
    padding: 2rem 0;
    text-transform: lowercase;
    border-bottom: 0.063rem solid ${palette.grey[100]};
    &:last-child {
        border-bottom: 0;
    }
    &:first-of-type {
        padding-top: 1.5rem;
    }
    &:last-of-type {
        padding-bottom: 1.5rem;
    }
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        display: flex;
        align-items: center;
        &:first-of-type {
            padding-top: 3rem;
        }
        &:last-of-type {
            padding-bottom: 3rem;
        }
    }
`;

export const StyledChangeButton = styled(ButtonBase)`
    flex: 0;
    padding: 0;
    color: ${palette.common.blue};
    font-size: inherit;
    font-family: inherit;
    text-decoration: underline;

    &:hover {
        color: inherit;
        text-decoration: underline;
        background: none;
    }
`;
