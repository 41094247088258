import { Theme } from '@material-ui/core';
import styled from 'styled-components';

export const GridContainer = styled.div<{ height?: number }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: ${({ theme }) => theme.spacing(20)};
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        flex-direction: row;
        height: ${properties =>
            properties.height ? `${properties.height}px` : 'auto'};
        column-gap: ${({ theme }) => theme.spacing(20)};
    }
`;
