/* stylelint-disable declaration-no-important */
import { ButtonBase } from '@material-ui/core';
import { Scrollbar } from 'react-scrollbars-custom';
import styled from 'styled-components';

import { palette } from '../../theme';
import { Icon } from '../Icon';
import {
    ScrollDirection,
    ScrollDirectionProperties,
} from './SimpleCarousel.types';

export const StyledCarouselContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    padding-bottom: 2rem;
`;

export const CarouselBase = styled.div<ScrollDirectionProperties>`
    position: relative;
    display: flex;
    box-sizing: border-box;
    overflow: scroll;
    list-style: none;
    &::-webkit-scrollbar {
        display: none;
    }
    scrollbar-width: none;
    mask-image: none;
`;

export const getMaskImage = (direction: ScrollDirection) => {
    switch (direction) {
        case ScrollDirection.Left:
            return 'linear-gradient(90deg, transparent 0%, black 10%)';
        case ScrollDirection.Right:
            return 'linear-gradient(90deg, black 90%, transparent 100%)';
        case ScrollDirection.Both:
            return 'linear-gradient(90deg, transparent 0%, black 10%, black 90%, transparent 100%)';
        default:
            return 'none';
    }
};

export const StyledCarousel = styled(CarouselBase)`
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        mask-image: ${({ $direction }) => getMaskImage($direction)};
    }
`;

export const StyledSlide = styled.div`
    padding: 0 1rem;

    &:first-child {
        padding-left: 0;
    }
`;

export const StyledSlides = styled.div`
    display: flex;
    align-items: stretch;
    padding: 1rem 3rem;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        padding: 1rem 8rem;
    }
`;

export const StyledIcon = styled(Icon)`
    && {
        cursor: pointer;
    }
`;

const StyledArrow = styled(ButtonBase)<ScrollDirectionProperties>`
    display: none;
    background-color: ${palette.common.white};
    border: 0.25rem solid ${palette.grey[900]};
    border-radius: 100%;
    cursor: pointer;
    &:focus-visible {
        outline: 0.2rem solid ${palette.primary.dark};
    }

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        display: block;
    }
`;

export const StyledArrowLeft = styled(StyledArrow)`
    position: absolute;
    left: 1rem;
    width: 4.8rem;
    height: 4.8rem;
    visibility: ${({ $direction }) => {
        switch ($direction) {
            case ScrollDirection.Left:
            case ScrollDirection.Both:
                return 'visible';
            case ScrollDirection.Right:
            case ScrollDirection.None:
            default:
                return 'hidden';
        }
    }};
`;

export const StyledArrowRight = styled(StyledArrow)`
    position: absolute;
    right: 1rem;
    width: 4.8rem;
    height: 4.8rem;
    visibility: ${({ $direction }) => {
        switch ($direction) {
            case ScrollDirection.Right:
            case ScrollDirection.Both:
                return 'visible';
            case ScrollDirection.Left:
            case ScrollDirection.None:
            default:
                return 'hidden';
        }
    }};
`;

export const StyledInnerArrowContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
`;

export const StyledArrowContainer = styled.div`
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 1rem;
    transform: translateY(-1rem);
`;

export const StyledInnerScrollContainer = styled.div<{ $width?: any }>`
    width: ${({ $width }) => ($width ? `${$width}px` : '100%')};
    height: 0.4rem;
`;

export const ScrollbarContainer = styled.div`
    position: absolute;
    bottom: 0.4rem;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 1rem;
    padding: 0 3rem;

    ${({ theme }) => theme.breakpoints.up('mobile')} {
        left: 50%;
        width: 32rem;
        transform: translateX(-50%);
    }
`;

export const StyledScrollbar = styled(Scrollbar)`
    .ScrollbarsCustom-Track {
        left: 0 !important;
        width: 100% !important;
        height: 0.4rem !important;
        background-color: ${palette.grey[900]} !important;
        cursor: pointer;
    }

    .ScrollbarsCustom-Thumb {
        background-color: ${palette.primary.main} !important;
    }
`;
