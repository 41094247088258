import { palette } from '../../../theme';
import { Icon } from '../../Icon';
import { ImageStandalone } from '../../ImageStandalone';
import { Paragraph } from '../../Paragraph';
import {
    StyledContainer,
    StyledImageContainer,
    StyledName,
    StyledCustomerLabel,
    StyledCustomerInfoContainer,
    StyledQuoteContainer,
    StyledIconContainer,
} from './Testimonial.styled';
import { TestimonialProperties } from './Testimonial.types';

/**
 * The Testimonial component.
 */
export const Testimonial = ({
    quote,
    customerName,
    customerLabel,
    image,
}: TestimonialProperties) => {
    return (
        <StyledContainer>
            <StyledQuoteContainer>
                <StyledIconContainer>
                    <Icon
                        name="quoteMarks"
                        color={palette.primary.main}
                        size={32}
                    />
                </StyledIconContainer>
                <Paragraph variant="body1">{quote}</Paragraph>
            </StyledQuoteContainer>
            <StyledCustomerInfoContainer>
                <StyledImageContainer>
                    <ImageStandalone {...image} altText={customerName} />
                </StyledImageContainer>
                <div>
                    <StyledName>{customerName}</StyledName>
                    {customerLabel && (
                        <StyledCustomerLabel aria-label="customer's gym location or title">
                            {customerLabel}
                        </StyledCustomerLabel>
                    )}
                </div>
            </StyledCustomerInfoContainer>
        </StyledContainer>
    );
};

export default Testimonial;
