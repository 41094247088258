import { useMemo, useRef } from 'react';

import { withLinkedImages } from '../../hocs/withLinkedImages/withLinkedImages';
import { StyledImage } from './ImageStandaloneFixedAspect.styled';
import { ImageStandaloneFixedAspectProperties } from './ImageStandaloneFixedAspect.types';

/**
 * Show an image with a fixed aspect at the full width of its container.
 */
function ImageStandaloneFixedAspectComponent({
    id,
    image,
    altText = '',
    className,
    lazy = true,
}: ImageStandaloneFixedAspectProperties) {
    const imgReference = useRef<HTMLImageElement>(null);

    const url = useMemo(() => {
        if (typeof image === 'string') {
            return image;
        }

        const { name, endpoint, defaultHost } = image;

        const amplienceUrl = `https://${defaultHost}/i/${endpoint}/${encodeURIComponent(
            name,
        )}?fmt=auto`;

        return amplienceUrl;
    }, [image]);

    return (
        <StyledImage
            ref={imgReference}
            id={id}
            src={url}
            alt={altText}
            loading={lazy ? 'lazy' : 'eager'}
            className={className}
        />
    );
}

export const ImageStandaloneFixedAspect = withLinkedImages(
    ImageStandaloneFixedAspectComponent,
);
