import styled from 'styled-components';

type StyledPromoTileLayoutProperties = {
    $maxWidth: number;
    $numberOfColumns: number;
};

export const StyledContainer = styled.div`
    margin: 2.5rem;
`;

export const StyledPromoTileLayout = styled.div<StyledPromoTileLayoutProperties>`
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat(1, minmax(calc(100% - 2.5rem), 1fr));
    gap: 2.5rem;
    max-width: ${({ $maxWidth }) => $maxWidth / 10}rem;
    margin: 0 auto;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        ${({ $numberOfColumns }) => `
      grid-template-columns: repeat(${$numberOfColumns}, minmax(calc(100% / ${$numberOfColumns} - 2.5rem), 1fr));
    `};
    }
`;
