import { ImageStandalone } from '../ImageStandalone';
import { GridContainer } from './MultipleImageComponent.styled';
import { MultipleImageComponentProperties } from './MultipleImageComponent.types';

export const MultipleImageComponent = ({
    images: [billboard, secondPack, thirdPack],
    height,
}: MultipleImageComponentProperties) => {
    return (
        <GridContainer height={height}>
            <ImageStandalone {...billboard} />
            {secondPack ? <ImageStandalone {...secondPack} /> : null}
            {thirdPack ? <ImageStandalone {...thirdPack} /> : null}
        </GridContainer>
    );
};
