import useMediaQuery from '@material-ui/core/useMediaQuery';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';

import { theme } from '../../theme';
import { ImageStandalone, ImageStandaloneProperties } from '../ImageStandalone';
import { LinkWithChevron } from '../LinkWithChevron';
import {
    StyledContainer,
    StyledDescription,
    StyledTextContainer,
    StyledTitle,
} from './PropositionHero.styled';
import { PropositionHeroProperties } from './PropositionHero.types';

/**
 * The `PropositionHero` component contains an image, title, description and optional link to highlight key services/campaigns
 */
export function PropositionHero({
    id,
    image,
    title,
    description,
    linkText,
    linkUrl,
}: PropositionHeroProperties) {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const containerReference = useRef<HTMLDivElement>(null);
    const imageHeightDesktop = 545;
    const imageHeightMobile = 192;
    const imageHeight = isDesktop ? imageHeightDesktop : imageHeightMobile;
    const [imageWithDimensions, setImageWithDimensions] =
        useState<ImageStandaloneProperties>(image);

    useEffect(() => {
        /* istanbul ignore next */
        const offsetWidth = containerReference.current?.offsetWidth ?? 0;

        setImageWithDimensions({
            ...image,
            height: imageHeight,
            width: offsetWidth,
        });
    }, [containerReference, isDesktop, image, imageHeight]);

    return (
        <StyledContainer id={id} ref={containerReference}>
            <ImageStandalone
                {...imageWithDimensions}
                maximumMobileImageHeight={imageHeightMobile}
            />
            <StyledTextContainer>
                <StyledTitle variant="h2" gutterBottom={false}>
                    {title}
                </StyledTitle>
                <StyledDescription>{description}</StyledDescription>
                {linkUrl && linkText && (
                    <Link href={linkUrl} passHref>
                        <LinkWithChevron href={linkUrl} text={linkText} />
                    </Link>
                )}
            </StyledTextContainer>
        </StyledContainer>
    );
}
