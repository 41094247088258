import styled from 'styled-components';

import { Theme } from '../../theme';
import { Heading } from '../Heading';

export const StyledContainer = styled.div<{
    $noGutterBottom?: boolean;
}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: ${({ theme, $noGutterBottom }) =>
        $noGutterBottom ? '0' : theme.spacing(40)};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding-bottom: ${({ theme, $noGutterBottom }) =>
            $noGutterBottom ? '0' : theme.spacing(80)};
    }
`;

export const StyledParagraphSection = styled.article`
    margin-bottom: 2rem;
    b {
        font-family: thegymgroupsans-Headline, sans-serif;
    }
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        text-align: center;
    }
`;

export const StyledSection = styled.section`
    display: flex;
    flex-direction: column;
    margin: 2.5rem 2.5rem 0;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        align-items: center;
        max-width: 85rem;
        margin: 3rem auto 0;
    }
`;

export const StyledHeading = styled(Heading)`
    margin-bottom: 1.5rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        text-align: center;
    }
`;

export const StyledChildren = styled.div<{ $isWider: boolean }>`
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        width: ${({ $isWider }) => ($isWider ? '84.9rem' : '51.5rem')};
    }
`;

export const StyledImageContainer = styled.div`
    display: flex;
    justify-content: center;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin-top: 4rem;
    }
`;
