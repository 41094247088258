import styled from 'styled-components';

import { Theme } from '../../theme';
import { Heading } from '../Heading';

export const StyledHeading = styled(Heading)`
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding-bottom: 0.5rem;
    }
`;

export const StyledFiitSection = styled.section`
    margin-bottom: 4rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        text-align: center;
    }
`;

export const StyledFiitLogo = styled.img`
    width: 4.7rem;
    margin-bottom: 1rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        width: 6.5rem;
    }
`;
