import styled from 'styled-components';

import { Theme } from '../../theme';
import { ButtonBase } from '../Button';

export const StyledForm = styled.form`
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        width: 41rem;
    }
`;

export const StyledSubmitButton = styled(ButtonBase)`
    margin-top: 3rem;
    font-size: 1.9rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        display: block;
        width: 17.7rem;
        margin: 4rem auto 1rem auto;
    }
`;
