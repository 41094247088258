import Link from 'next/link';

import {
    GymAddress,
    GymCTAContainer,
    GymLowestPrice,
    GymName,
    InfoWindowContainer,
} from '../GoogleMap.styled';
import { GymInfoWindowProperties } from '../GoogleMap.types';

/**
 * The GymInfoWindow component renders Gym related information when selecting a marker on Google Maps.
 * * The `id` given to the Gym Info `button` is used to attach event listeners for the info window,
 * as this component cannot be rendered directly as a React component within the map (so handlers
 * cannot be passed as props).
 */
export function GymInfoWindow({
    gymName,
    gymAddress,
    lowestPrice,
    latitude,
    longitude,
}: GymInfoWindowProperties) {
    return (
        <InfoWindowContainer>
            <GymName role="link" id="gym-info-window-link" tabIndex={0}>
                {gymName}
            </GymName>
            <GymAddress
                href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`}
                target="_blank"
            >
                {gymAddress}
            </GymAddress>
            <GymLowestPrice>From £{lowestPrice} per month</GymLowestPrice>
            <GymCTAContainer>
                <button type="button" id="gym-info-window-button">
                    View Gym
                </button>
            </GymCTAContainer>
        </InfoWindowContainer>
    );
}
