import styled from 'styled-components';

import { palette } from '../../theme';
import { TextInput } from '../Forms/TextInput';
import { Heading } from '../Heading';
import { Tile } from '../Tile';

export const StyledPromoCodeTile = styled(Tile).attrs({
    variant: 'secondary',
})`
    justify-content: center;
    margin-top: 3rem;
    padding: 3rem;
    text-align: center;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-top: 4rem;
    }
`;

export const StyledTextInputContainer = styled.div`
    max-width: 25.5rem;
    margin: auto;
`;

export const StyledHeading = styled(Heading)`
    color: ${palette.common.blue};
`;

export const StyledTextInput = styled(TextInput)<{
    disabled: boolean;
}>`
    ${({ disabled }) =>
        disabled
            ? `
            opacity: 1;
            background-color: ${palette.common.white};

            svg path {
                stroke: ${palette.primary.main};
                fill: ${palette.primary.main};
            }
    `
            : ''};
`;
