import styled from 'styled-components';

import { Theme } from '../../../theme';
import { SimpleCarousel } from '../../SimpleCarousel';

export const SimpleCarouselStyled = styled(SimpleCarousel)`
    button {
        bottom: 1.5rem;
        ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
            bottom: 0;
        }
    }
`;
