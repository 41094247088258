import styled from 'styled-components';

import { palette } from '../../../theme';

export const Wrapper = styled.div`
    height: 100%;
    max-height: 31rem;
    margin: 0;
    overflow-y: auto;
`;

export const StyledUl = styled.ul`
    margin-top: 0;
    li {
        border-bottom: 0.1rem solid #dbdbdb;
    }
    li:last-child {
        border-bottom: none;
    }
`;

export const StyledLi = styled.li<{ $selected: boolean }>`
    display: flex;
    align-items: center;
    height: ${({ theme }) => theme.spacing(40)};
    font-weight: normal;
    font-size: 1.4rem;
    list-style: none;
    background-color: ${({ $selected, theme }) =>
        $selected && palette.grey[100]};
    cursor: pointer;
    &:focus {
        background-color: ${palette.grey[100]};
        outline: none;
    }
    &:hover {
        background-color: ${palette.grey[100]};
    }
`;

export const StyledHeading = styled.p<{ $instructionTip?: string }>`
    margin: ${({ theme }) => `${theme.spacing(5)} 0 0 4rem`};
    padding: 0;
    font-size: 1rem;
`;
