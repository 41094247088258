import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';

import { palette, theme } from '../../theme';
import { Icon } from '../Icon';
import {
    StyledContainer,
    StyledIcon,
    StyledName,
    StyledListItem,
    StyledList,
    StyledNameContainer,
    StyledButtonLink,
} from './GymNameTile.styled';
import { GymNameTileProperties } from './GymNameTile.types';

export function GymNameTile({
    name,
    icon,
    links = [],
    disabled = false,
    isSummary = true,
}: GymNameTileProperties) {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const hasLinks = links.length > 0;

    return (
        <StyledContainer
            data-testid={`gym-name-tile-${name}`}
            $disabled={disabled}
        >
            <StyledIcon>
                <Icon
                    name={icon}
                    color={palette.primary.main}
                    size={isDesktop ? 40 : 25}
                />
            </StyledIcon>
            <StyledNameContainer $listPadding={hasLinks}>
                <StyledName
                    component="h5"
                    variant="h4"
                    gutterBottom={false}
                    $isSummary={isSummary}
                >
                    {name}
                </StyledName>
                {hasLinks && (
                    <StyledList>
                        {links.map(({ text, handleLinkClick }) => (
                            <StyledListItem key={text}>
                                <StyledButtonLink onClick={handleLinkClick}>
                                    {text}
                                </StyledButtonLink>
                            </StyledListItem>
                        ))}
                    </StyledList>
                )}
            </StyledNameContainer>
        </StyledContainer>
    );
}
