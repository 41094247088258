import { PropositionThumbnail } from '../PropositionThumbnail';
import { SimpleCarouselStyled } from './PropositionThumbnailGroup.styled';
import { PropositionThumbnailGroupProperties } from './PropositionThumbnailGroup.types';
import { encodeAsBase64 } from '@tgg/util';

/**
 * Use the `PropositionThumbnailGroup` component to display a carousel of Proposition Thumbnails.
 */
export const PropositionThumbnailGroupBase = ({
    id,
    data,
    linkHidden,
    onClick,
    prioritiseImageLoading = false,
}: PropositionThumbnailGroupProperties) => {
    return (
        <SimpleCarouselStyled
            id={id}
            galleryElements={data.map((item, index) => {
                return (
                    <PropositionThumbnail
                        linkHidden={linkHidden}
                        prioritiseImageLoading={prioritiseImageLoading}
                        key={encodeAsBase64(`${item.text}${index}`)}
                        onClick={
                            onClick
                                ? () =>
                                      onClick(
                                          index,
                                          item.text,
                                          item.linkUrl,
                                          item.linkText,
                                      )
                                : undefined
                        }
                        {...item}
                    />
                );
            })}
        />
    );
};

export default PropositionThumbnailGroupBase;
