import styled from 'styled-components';

import { containerSmallWidth, Theme } from '../../theme';
import { Heading } from '../Heading';
import { Paragraph } from '../Paragraph';

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        align-items: center;
        justify-content: center;
        margin: 8rem 8rem 0 8rem;
    }
`;

export const StyledTextContainer = styled.div`
    margin: 0 3rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin: 0;
        text-align: center;
    }
`;

export const StyledTitle = styled(Heading)`
    margin: 3rem 0 1rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin: 4rem 0 3rem;
    }
`;

export const StyledDescription = styled(Paragraph)`
    && {
        margin-bottom: 1rem;

        ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
            max-width: ${containerSmallWidth};
            margin-bottom: 3rem;
        }
    }
`;
