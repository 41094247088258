import useMediaQuery from '@material-ui/core/useMediaQuery';

import { theme } from '../../theme';
import { Checklist } from '../Checklist';
import { Paragraph } from '../Paragraph';
import { StyledHeading, StyledWrapper } from './Steps.styled';
import { StepsProperties } from './Steps.types';

export function Steps({ id, heading, description, steps }: StepsProperties) {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const list = steps.map(step => ({
        subTitle: step.heading,
        description: step.description,
        image: step.image,
    }));

    const headingComponent = isDesktop ? 'h2' : 'h3';

    return (
        <StyledWrapper id={id}>
            <StyledHeading
                variant="h3"
                component={headingComponent}
                gutterBottom={false}
                $withSpacing={!description}
            >
                {heading}
            </StyledHeading>
            {description && <Paragraph>{description}</Paragraph>}
            <Checklist list={list} />
        </StyledWrapper>
    );
}

export default Steps;
