import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { format, isBefore, subDays } from 'date-fns';

import { palette } from '../../../../theme';

export const formatDate = (date?: Date) => {
    if (!date) return '';

    return format(date, 'yyyy-MM-dd');
};

export const disableSpecificDates = (
    date: MaterialUiPickersDate,
    disabledDates: (string | undefined)[],
) => {
    if (!date) return false;

    const formattedDate = formatDate(date);

    return disabledDates.includes(formattedDate);
};

export const renderDay = (
    day: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean,
    dayComponent: JSX.Element,
    disabledDates: (string | undefined)[],
) => {
    const isSelected =
        day && selectedDate && formatDate(day) === formatDate(selectedDate);
    const isDisabled =
        disableSpecificDates(day, disabledDates) ||
        isBefore(day as Date, subDays(new Date(), 1));

    const dayStyles = {
        color: isSelected
            ? palette.primary.dark
            : isDisabled
            ? palette.grey[200]
            : undefined,
    };

    return (
        <div data-testid="day" style={dayStyles}>
            {dayComponent}
        </div>
    );
};
