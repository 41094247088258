import styled from 'styled-components';

import { palette } from '../../theme';
import { Paragraph } from '../Paragraph';

export const StyledCircle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 15rem;
    height: 15rem;
    padding: 1rem 2rem 0 2rem;
    text-align: center;
    border: 3px solid #e5effa;
    border-radius: 50%;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        width: 19rem;
        height: 19rem;
        padding: 1rem 3rem 0 3rem;
    }
`;

export const StyledCount = styled.div`
    margin-bottom: 1rem;
    color: ${palette.common.blue};
    font-size: 4rem;
    font-family: thegymgroupsans-Headline;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-bottom: 2rem;
        font-size: 5.4rem;
    }
`;

export const StyledParagraph = styled(Paragraph)`
    font-size: 1.6rem;

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.8rem;
    }
`;
