import { StepConnector } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import styled from 'styled-components';

import { palette, Theme, theme } from '../../theme';

export const StyledProgressBarContainer = styled.div`
    background-color: ${palette.common.white};
`;

export const StyledStep = styled(Step)``;

export const StyledStepper = styled(Stepper)`
    margin: auto;
    padding: 1rem 2.1rem;
    background-color: ${palette.common.white};

    ${theme.breakpoints.up('desktop')} {
        width: 100%;
        max-width: 98rem;
    }
`;

export const StyledStepLabel = styled(StepLabel)`
    text-transform: lowercase;

    &.Mui-disabled {
        svg {
            opacity: 50%;
        }
    }

    & .MuiStepLabel-label {
        margin-top: 0;
        color: ${palette.common.blue};
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.6rem;

        ${theme.breakpoints.up('desktop')} {
            font-size: 1.2rem;
            line-height: 1.8rem;
        }
    }

    & .MuiStepLabel-active {
        margin-top: 0;
        font-family: thegymgroupsans-headline;
    }

    a {
        color: inherit;
        text-decoration: inherit;
    }
`;

export const StyledStepConnector = styled(StepConnector)`
    &.MuiStepConnector-alternativeLabel {
        top: 1rem;
        right: calc(50% + 3rem);
        left: calc(-50% + 3rem);
        ${theme.breakpoints.up('desktop')} {
            top: 1.6rem;
        }
    }

    & .MuiStepConnector-lineHorizontal {
        border-color: ${palette.grey[200]};
        border-radius: 0.05rem;
    }

    &.MuiStepConnector-active {
        & .MuiStepConnector-lineHorizontal {
            border-color: ${palette.primary.main};
        }
    }

    &.MuiStepConnector-completed {
        & .MuiStepConnector-lineHorizontal {
            border-color: ${palette.primary.main};
        }
    }
`;
