import styled from 'styled-components';

export const StyledOl = styled.ol`
    padding-left: 1.8rem;

    li {
        margin-bottom: 2rem;
        padding-left: 0.8rem;

        p {
            display: inline-block;
            vertical-align: top;
        }
    }
`;
