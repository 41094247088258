/* istanbul ignore file */
import { DatePicker } from '@material-ui/pickers';
import styled from 'styled-components';

import { palette } from '../../../theme';

export const StyledDateInputContainer = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 2rem;
    border-style: solid;
    border-width: 0.1rem;
    border-radius: 2.5rem;
    cursor: pointer;

    * {
        cursor: pointer;
    }
`;

export const StyledDateInput = styled(DatePicker)`
    flex-direction: unset;
    color: ${palette.common.blue};
    font-size: 1.6rem;
    background: none;
    border: none;
    outline: none;
    input {
        padding: 0;
        border: none;
    }

    div {
        display: flex;
        align-items: end;
    }
`;
