import styled from 'styled-components';

import { Theme, palette } from '../../theme';
import { Heading } from '../Heading';

export const StyledWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 4rem 1.5rem 2.5rem;
    background: ${palette.primary.main};

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        padding: 6rem 6rem 4.5rem;
    }
`;

export const StyledContainer = styled.div`
    max-width: ${({ theme }: { theme: Theme }) =>
        theme.breakpoints.values.largeDesktop}px;
`;

export const StyledHeading = styled(Heading)`
    && {
        margin: 0 1.5rem 1rem;

        ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
            text-align: center;
        }
    }
`;

export const StyledItem = styled.div`
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    padding: 1.5rem;
    font-weight: 100;

    /* TODO fix this */
    /* Possibly see these issues: https://github.com/styled-components/stylelint-processor-styled-components/issues/201 https://github.com/stylelint/stylelint/issues/2489 */
    /* stylelint-disable-next-line no-descending-specificity */
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        align-items: center;
        width: 33.3%;
        padding: 3rem;
        text-align: center;
    }

    span {
        color: ${palette.common.blue};
    }
`;

export const StyledItemTitle = styled(Heading)`
    && {
        margin: 1.5rem 0 1rem;
        color: ${palette.common.white};

        ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
            margin: 2rem 0 1.5rem;
        }
    }
`;
