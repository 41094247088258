import styled from 'styled-components';

import { palette } from '../../../../theme';

export const StyledGroupedUnorderedListItem = styled.li`
    display: inline-flex;
    align-items: center;
    height: 5rem;
    padding-right: 1rem;
    text-transform: lowercase;
    list-style: none;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        align-items: end;
        height: 7.6rem;
        padding-right: 0;
        padding-bottom: 1rem;
    }

    a {
        color: ${palette.common.blue};

        strong {
            font-weight: normal;
            font-family: thegymgroupsans-Headline;
        }
    }
`;
