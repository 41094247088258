import styled from 'styled-components';

import { Theme } from '../../../theme';

interface StyledContainerButtonProperties {
    $fullWidth: boolean;
}

export const StyledWrapperButton = styled.div`
    width: 100%;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        display: flex;
        justify-content: center;
    }
`;
export const StyledContainerButton = styled.div<StyledContainerButtonProperties>`
    margin: 0 ${({ theme, $fullWidth }) => ($fullWidth ? 0 : theme.spacing(30))};
`;
