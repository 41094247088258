import styled from 'styled-components';

import { Theme, palette } from '../../theme';
import { Heading } from '../Heading';

export const StyledBody = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: ${({ theme }) => theme.spacing(24)};
    padding: 2.4rem 3rem;
    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        display: grid;
        grid-auto-columns: minmax(0, 1fr);
        padding: ${({ theme }) => theme.spacing(40)};
    }
    a:last-of-type > div {
        border-bottom: none;
    }
`;

export const StyledHeading = styled(Heading)`
    align-content: center;
    white-space: nowrap;
`;

export const StyledCategoryBox = styled.div`
    max-width: fit-content;
    padding: 0 ${({ theme }) => theme.spacing(20)};
    padding-top: ${({ theme }) => theme.spacing(8)};
    padding-bottom: ${({ theme }) => theme.spacing(4)};
    font-size: inherit;
    background-color: ${palette.grey[100]};
    border-style: none;
    border-radius: 1.9rem;
`;

export const StyledCategoryContainer = styled.article`
    display: flex;
    flex-direction: column;
    margin-top: ${({ theme }) => theme.spacing(20)};
`;

export const StyledCategoryItems = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: ${({ theme }) => theme.spacing(20)};
    margin-top: ${({ theme }) => theme.spacing(10)};
    column-gap: ${({ theme }) => theme.spacing(10)};
`;

export const StyledArticle = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: ${({ theme }) => theme.spacing(20)};
    border-bottom: 0.1rem solid ${palette.grey[100]};
`;

export const StyledLink = styled.a`
    width: 100%;
    color: ${palette.common.blue};
    text-decoration: none;
    cursor: pointer;
    svg {
        min-width: max-content;
        margin-bottom: 0.1rem;
        cursor: pointer;
    }
    h4 {
        overflow: hidden;
        white-space: normal;
        text-overflow: ellipsis;
    }
`;
